.container {
  width: 120px;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 410;
  /* background: #cccccc; */
}

.rocket {
  position: relative;
  transition: transform 0.3s ease;
}

.rocket img {
  height: auto;
  position: relative;
  z-index: 2;
}

.launch {
  animation: launch 5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.fire {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 60px;
  z-index: 1;
}

.fire span {
  position: absolute;
  bottom: 0;
  width: 12px;
  height: 30px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: linear-gradient(to top, 
    rgba(255, 77, 77, 0.8), 
    rgba(255, 153, 51, 0.9), 
    rgba(255, 204, 51, 0.3)
  );
  filter: blur(2px);
  animation: flame 0.5s infinite;
}

.fire span:nth-child(1) { left: 25%; height: 25px; animation-delay: -0.1s; }
.fire span:nth-child(2) { left: 45%; height: 30px; animation-delay: -0.2s; }
.fire span:nth-child(3) { left: 65%; height: 25px; animation-delay: -0.3s; }

.particles {
  position: absolute;
  bottom: -30px;
  /* left: 50%; */
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  z-index: 1;
}

.particle {
  position: absolute;
  width: 4px;
  height: 4px;
  background: #ff9933;
  border-radius: 50%;
  animation: particle 2s infinite;
}

.launchButton {
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  background: #ff4d4d;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.launchButton:hover {
  background: #ff3333;
  transform: scale(1.05);
}

.launchButton:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

@keyframes launch {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  5% {
    transform: translateY(-20px) rotate(2deg);
  }
  15% {
    transform: translateY(-100px) rotate(-2deg);
  }
  30% {
    transform: translateY(-300px) rotate(1deg);
  }
  100% {
    transform: translateY(-2000px) rotate(0deg);
  }
}

@keyframes flame {
  0%, 100% {
    transform: scaleY(1);
    opacity: 0.8;
  }
  50% {
    transform: scaleY(1.2);
    opacity: 1;
  }
}

@keyframes particle {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(var(--offset-x, 20px), var(--offset-y, 50px)) scale(0);
    opacity: 0;
  }
} 