.bulletCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 999;
}

.bulletMessagesContainer {
    position: relative;
    width: 100%;
    overflow: visible;
}

.bulletMessageContainer {
    position: absolute;
    white-space: nowrap;
    width: 100%;
    display: flex;
    gap: 6px;
    align-items: center;
    overflow: visible;
}

.floor0 {
    padding-top: 1rem;
}

.floor1 {
    top: 3rem;
}

.floor2 {
    top: 5rem;
}

.floor3 {
    top: 7rem;
}

.floor4 {
    top: 9rem;
}

.floor5 {
    top: 11rem;
}

.floor6 {
    top: 13rem;
}

.floor7 {
    top: 15rem;
}


.bulletMessageText {
    font-size: 1.4rem;
}

@keyframes bulletMove {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-200%);
    }
}

@keyframes bulletMoveWithPadding {
    from {
        transform: translateX(calc(100% - 19vw));
    }

    to {
        transform: translateX(-200%);
    }
}