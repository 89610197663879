.discord-cta-container {
    background-color: #5865F2;
    padding: 30px;
    border-radius: 12px;
    margin: 30px auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    animation: pulse 2s infinite;
    min-height: 190px;
    max-width: 720px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.discord-cta-text {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.discord-join-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #5865F2;
    font-size: 24px;
    font-weight: bold;
    padding: 18px 36px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 70%;
    text-decoration: none;
}

.discord-join-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.discord-logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}


.streamsGallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
    gap: 20px;
    padding: 10px;
}

@media (max-height:500px),
(max-width:600px) {
    .streamsGallery {
        grid-template-columns: 1fr;
    }
}

.trendingStreamItem {
    position: relative;
    width: 100%;
    cursor: pointer;
}