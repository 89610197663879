.overlayBetPreviewWrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
    box-sizing: border-box;
}

.overlayBetPreview {
    display: flex;
    justify-content: center;
    transform-origin: bottom;
    transform: scale(0.8);
}

@media (max-width:600px) {
    .overlayBetPreview {
        position: absolute;
        display: flex;
        justify-content: center;
        transform: scale(0.45);
    }
}

.overlayTrendingBetWrap {
    position: absolute;
    display: flex;
    justify-content: center;
}

.overlayTrendingBet {
    background-color: black;
    border-radius: 10px;
    padding: 10px;
}

.overlayBetHead {
    display: flex;
    justify-content: space-between;
}

.overlayBetMarkNew {
    background-color: green;
    font-size: 13px;
    border-radius: 15px;
    padding: 5px 10px;
}

.overlayBetMarkHot {
    background-color: rgb(148, 0, 0);
    font-size: 13px;
    border-radius: 15px;
    padding: 5px 10px;
}

.overlayBetCountdown {
    font-size: 13px;
}

.overlayBetTitleText {
    min-width: 80px;
}

@media (max-height:500px) {
    .overlayTrendingBet {
        min-width: 150px;
    }

    .overlayBetTitle {
        font-size: 13px;
    }

    .overlayBetTitleText {
        max-width: 150px;
    }

    .overlayBetOptionText {
        padding: 5px;
        font-size: 12px;
    }

    .overlayBetOptionSub {
        font-size: 11px;
        padding: 0;
    }

    .overlayBetInfo {
        font-size: 11px;
    }
}

@media (min-height:500px) {
    .overlayTrendingBet {
        min-width: 300;
    }

    .overlayBetTitle {
        font-size: 20px;
    }

    .overlayBetOptionText {
        padding: 15px;
    }

    .overlayBetOptionSub {
        font-size: 14px;
        padding: 3px;
    }

    .overlayBetInfo {
        font-size: 13px;
    }
}

.overlayBetTitle {
    padding: 0 0 10px 0;
    font-weight: bold;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 3px;
}

.overlayBetInfo {
    font-weight: normal;
    padding: 2px;
}

.overlayBetBody {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.overlayBetOption {
    flex: 1;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.overlayBetOption0 {
    background-color: #00e701;
}

.overlayBetOption0:hover {
    background-color: #1fff20;
}

.overlayBetOption1 {
    background-color: #FFD42A;
}

.overlayBetOption1:hover {
    background-color: #FFEB3B;
}

.overlayBetOption2 {
    background-color: rgb(0, 151, 201);
}

.overlayBetOption2:hover {
    background-color: rgb(51, 173, 217);
}

.overlayBetOptionText {
    font-weight: bold;
    text-align: center;
}

.overlayBetOptionSub {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0 0 6px 6px;
    text-align: center;
}

@keyframes betDoubleFadeIn {
    0% {
        opacity: 0.4;
        transform: scale(0.95);
    }

    25% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0.4;
        transform: scale(0.95);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.betAnimation {
    animation: betDoubleFadeIn 0.6s ease-out;
}

.betNoAnimation {}


@keyframes userBetfloatUp {
    0% {
        transform: translate(-50%, 0);
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    99% {
        transform: translate(-50%, -50px);
        opacity: 0;
    }

    100% {
        transform: translate(-50%, -50px);
        opacity: 0;
        display: none;
    }
}

.betAnimationPop {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 15px;
    animation: userBetfloatUp 2s ease-in-out forwards;
    background-color: rgb(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    text-wrap: wrap;
    white-space: nowrap;
    z-index: 11;
    overflow: hidden;
}

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.circle {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border: 4px solid transparent;
    background: conic-gradient(#4db8ff 0%, #e6e6e6 0%);
    transition: background 1s linear;
}

.num {
    font-size: 10px;
    font-weight: bold;
    color: #333;
}