.streamViewContainer {
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: inherit;
    background-color: black;
    overflow: hidden;
    height: calc(100vh - 45px);
    margin-top: 45px;
}

.plainBtn {
    cursor: pointer;
    display: flex;
    gap: 2px;
    align-items: center;
}

.plainBtn span {
    color: #ccc;
    font-size: 15px;
}

.popover {
    transform-origin: right bottom;
    transform: scale(1);
}

.streamViewToolbar {
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 45px;
}

@media (max-height:500px),
(max-width:500px) {
    .plainBtn span {
        font-size: 12px;
    }

    .streamViewContainer {
        height: calc(100vh - 35px);
        margin-top: 35px;
    }

    .streamViewToolbar {
        height: 55px;
        padding-bottom: 12px;
        gap: 2px;
    }

    .popover {
        transform: scale(0.8);
    }
}


.streamChat {
    display: flex;
    flex-direction: column;
    border-color: var(--color-border);
    border-style: solid;
}

.createBet {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 100%;
    height: 3rem;
    margin: 0;
    gap: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid var(--color-border);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.streamerRoomChatList {
    flex-grow: 1;
    overflow-y: auto;
}

.chatMessageItemContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 1rem;

}

.streamContainer {
    flex: 1;
}

.chatMessageDisplayGiftBtn {
    width: 21px;
    height: 21px;
    cursor: pointer;
    float: right;
    padding-left: 5px;
}

.chatMessageDisplayGiftBtn img {
    max-width: 100%;
    max-height: 100%;
}

.chatMessageDisplayText {
    margin-left: 0.5rem;
    align-items: center;
    white-space: pre-wrap;
    word-wrap: break-word;
    position: relative;
    width: 100%;
}

.chatMessageDisplayTextWithAirdrop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.claimAirdropText {
    margin-left: 0.5rem;
}

.claimedOrExpiredAirdropText {
    margin-left: 0.5rem;
    color: var(--colors-gray);
}

.claimAirdropText {
    color: var(--color-btn);
}

.claimAirdropText,
.claimAirdropText {
    text-decoration: underline;
    cursor: pointer;
}

.streamTabContainerItem {
    cursor: pointer;
    color: var(--color-light-2);
    /* background-color: linear-gradient(90deg, #e2e7ec 0%, #ebf0f5 23%); */
    background-color: var(--color-dark-3);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    border-bottom: none;
}

.chatSendButton {
    background-color: var(--color-btn);
    position: absolute;
    right: 1.4rem;
    border: none;
    cursor: pointer;
    padding: 0.3rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    border-radius: 0.4rem;
    transition: transform 0.3s ease;
}

.chatSendButton:hover {
    transform: scale(1.05);
}

.streamerRoomChatInput {
    outline-color: var(--color-btn);
}

.streamInteractionFooter {
    margin-top: auto;
    /* Push footer to the bottom */
    display: flex;
    flex-direction: column;
}

.streamerCoinTipsEarningContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: var(--colors-gray);
    font-size: 0.9rem;
    padding: 0.5rem;
    padding-top: 0;
    gap: 0.5rem;
}

.streamerCoinTipsEarningItem {
    display: flex;
    flex-direction: row;
    position: relative;
}

.streamerCoinTipsEarningItemPop {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 3px;
    border: 1px solid white;
    animation: earningPopfloatUp 2s ease-in-out forwards;
    background-color: #b17215;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.streamerCoinTipsEarningItemPop:after {
    border-color: #b17215 transparent transparent transparent;
}

.streamerCoinTipsEarningItemPop:before {
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.streamerCoinTipsEarningImage {
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    margin-right: 0.2rem;
    align-self: center;
}

.inStreamEarningContainer {
    display: flex;
    flex-direction: column;
    color: var(--colors-gray);
    font-size: 0.9rem;
    padding: 0.5em 0.5rem;
    position: relative;
}

.inStreamEarningText {
    align-self: center;
}

.onlineUserContainer {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 3px;
    font-size: 1rem;
}

@media (max-height:500px),
(max-width:500px) {
    .inStreamEarningContainer {
        font-size: 0.7rem;
    }

    .onlineUserContainer {
        font-size: 0.8rem;
    }
}

.inStreamEarningContainerPop {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-weight: bold;
    padding: 5px 20px;
    border-radius: 3px;
    border: 1px solid white;
    animation: earningPopfloatUp 2s ease-in-out forwards;
}

.iecp_green {
    background-color: rgb(5, 153, 0);
}

.iecp_green:after {
    border-color: rgb(5, 153, 0) transparent transparent transparent;
}

.iecp_green:before {
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.iecp_red {
    background-color: rgb(153, 0, 0);
}

.iecp_red:after {
    border-color: rgb(153, 0, 0) transparent transparent transparent;
}

.iecp_red:before {
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.inStreamEarningContainerPop::before,
.streamerCoinTipsEarningItemPop::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 0 12px;
}

.inStreamEarningContainerPop::after,
.streamerCoinTipsEarningItemPop::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
}

@keyframes earningPopfloatUp {
    0% {
        transform: translate(-50%, 0);
        opacity: 0;
    }

    15% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        transform: translate(-50%, -30px);
        opacity: 0;
    }
}

.giftReactionsContainer {
    display: flex;
    flex-direction: row;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: fit-content;
    gap: 0.2rem;
}

.trendingBetsContainer {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: var(--color-border) 1px solid;
}


.popup {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    border: 1px solid var(--color-border);
    border-radius: 1rem;
    padding: 1.2rem;
    background-color: var(--color-dark-3);
    width: 25rem !important;
    min-height: 10rem;
    max-width: 95%;
    font-size: 1.2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translate(-50%, -50%) scale(1);
}

@media (max-height:500px),
(max-width:500px) {
    .popup {
        transform: translate(-50%, -50%) scale(0.7);
    }
}



.commentatorToolsContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.addCommentatorOfStream {
    flex: 1;
    display: flex;
    flex-direction: row;
    border: 2px solid rgb(0, 186, 250);
    border-radius: 0.3rem;
    padding: 0.6rem;
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-self: center;
    text-wrap: nowrap;
}

.commentatorVideoIcon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.25rem;
    align-self: center;
    justify-content: center;
}

.commentatorVideoText {
    font-size: 1rem !important;
    text-align: center;
    align-self: center;
}

.chatMessagePfpImage {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    font-size: 1.7rem;
    vertical-align: middle;
    object-fit: cover;
}

.chatMessagePfpImageGift {
    width: 2rem;
    height: 2rem;
    font-size: 1.7rem;
    vertical-align: middle;
    object-fit: cover;
}

.featuredStreamButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.betModFooter {
    flex-wrap: wrap;
}

.betModFooter span {
    display: block;
    text-wrap: nowrap;
}


.hostStreamPlayer {
    z-index: 500;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.streamPlayer {
    height: 100%;
    width: 100%;
    /* aspect-ratio: 1280 / 720; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: var(--color-border) 1px solid;
}

.previewStreamPlayer {
    height: auto;
    width: 100%;
    aspect-ratio: 1280 / 720;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: var(--color-border) 1px solid;
}

.commentatorPfpInStreamPlayer {
    z-index: 2;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10
}

.commentatorPfpInStreamPlayer img {
    width: 80px;
    height: 80px;
}

.paymentNextBtn {
    font-size: 1.1rem !important;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0.7rem 0rem !important;
}

.spinnerNano {
    width: 15px;
    height: 15px;
    border: 5px solid rgba(255, 255, 255, 0.5);
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.spinnerMini {
    width: 20px;
    height: 20px;
    border: 5px solid rgba(255, 255, 255, 0.5);
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.spinner {
    width: 36px;
    height: 36px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #3498db;
    border-radius: 50%;
    /* 圆形 */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.streamCommentatorInfo {
    font-size: 1rem;
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.streamCommentatorInfo img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.animationCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* ######### mixed */
@media (max-width:600px) {

    .featuredStreamPlayer {
        border-bottom: 1px solid var(--color-border) !important;
    }

    .commentateStreamPlayer {
        z-index: 500;
        display: flex;
        width: 20vw;
        height: calc(20vw * 720 / 1280);
        position: absolute;
        top: 0;
        right: 0;
    }

    .countdownTextContainer {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .commentatorVideoIcon {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.4rem;
    }

    .streamChat {
        width: 100vw;
        border-width: 1px 0 1px 0;
    }

    .twitter-tweet {
        margin: 0px auto !important;
    }

    /** bets **/
    .betsContainer {
        width: 100vw;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .trendingBetsContainer {
        width: 100vw;
        max-height: 15rem;
    }

    .trendingBetContainer,
    .trendingBetContainerForMod {
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 30rem;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        position: relative;
    }

    .allBetsBetsContainer {
        justify-content: flex-start;
        overflow-y: scroll;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: 100vw;
    }

    .betItemContainer {
        width: 100vw;
        border-top: 1px solid var(--color-border);
        padding: 0.8rem 1rem;
        display: block;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    /** bet header **/
    .streamTabContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 2.2rem;
    }

    .streamerRoomChatList {
        border-top: 1px solid var(--color-border);
    }

    .streamTabContainerItem,
    .selectedStreamTabContainer {
        flex: 1;
        text-align: center;
        padding: 0.5rem;
        border-top: none !important;
    }

    .rightMostStreamTabContainerItem {
        border-right: none;
    }

    .selectedStreamTabContainer {
        border-top: solid 1px black;
    }

    /** selected bet*/
    .selectedBetItemHeader {
        display: flex;
        flex-direction: row;
        word-break: break-word;
        font-weight: 500;
    }

    .placeBetCloseIcon {
        margin-left: auto;
        width: 1rem;
        height: 1rem;
    }

    .selectedBetInputContainer {
        position: relative;
        margin-top: 0.5rem;
    }

    .selectedBetAmountInput {
        width: calc(100% - 1rem);
        height: 1.8rem;
        margin-top: 0.5rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .buttonContainer {
        position: absolute;
        top: calc((1.8rem + 0.4rem * 2 - 1.7rem) / 2 + 0.5rem);
        right: 0.55rem;
        display: flex;
        gap: 0.5rem;
    }

    .selectedBetPlus {
        background-color: var(--colors-gray-3);
        color: var(--colors-gray);
        font-size: 1.1rem;
        padding: 0.3rem;
        height: 1.7rem;
        border-radius: 0.4rem;
    }

    .selectedBetPlus:hover {
        background-color: var(--colors-gray-2);
    }

    .afterBetWinTicketsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.6rem;
    }

    .betBoxToWinCoinImage {
        width: 1.05rem;
        height: 1.05rem;
        margin-left: 0.3rem;
        margin-right: 0.2rem;
    }

    .placeBetBtn {
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem 0;
        align-items: center;
        justify-content: center;
    }

    .placeBetHintContainer {
        display: flex;
        flex-direction: column;
    }

    /** unselected bet*/
    .betTitleContainer {
        display: flex;
        flex-direction: row;
        padding-bottom: 0.6rem;
        line-height: 1.5rem;
        word-break: break-word;
    }

    .betTitleDecisionContainer {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center
    }

    .betTitleDecisionHintTrigger {
        margin-right: 0.5rem;
    }

    .betItemOptionName {
        word-break: break-word;
        padding-right: 1rem;
    }

    .betItemOptionsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .betItemOptionOdds {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .betItemOptionOddsHint {
        color: var(--colors-gray);
        font-size: 0.85rem;
        margin-left: 0.2rem;
        font-weight: 500;
    }

    .userBettedAmount {
        text-align: end;
        font-size: 0.85rem;
        margin-top: 0.35rem;
        color: var(--colors-gray);
    }

    .betBtn {
        margin-left: 0.5rem;
        font-size: 1.15rem;
        padding: 0.25rem 0.8rem;
    }

    .betBtn:hover {
        background-color: hsl(195, 100%, 64%);
    }

    .betItemFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.85rem;
        font-weight: 500;
        margin-top: 0.6rem;
        color: var(--colors-gray);
    }

    .betItemTVL {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .betBoxCoinImage {
        margin-left: 0.4rem;
        margin-right: 0.15rem;
        width: 0.9rem;
        height: 0.9rem;
    }

    .betLivenessContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: 0.1rem;
    }

    .betLivenessIcon {
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background-color: green;
    }

    .shareBetContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .shareBetIcon {
        width: 0.9rem;
        height: 0.9rem;
        margin-left: 0.5rem;
        margin-right: 0.25rem;
    }

    .shareBetLink {
        text-decoration: underline;
    }

    .twitterShare {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .twitterShareIcon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }

    .twitterShareLink {
        font-size: 1rem;
    }

    .shareUrlContainer {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 0.4rem;
        align-items: center;
    }

    .shareUrlText {
        max-width: 75%;
        overflow: scroll;
    }

    .shareUrlContainerCopyButton {
        margin-left: auto;
    }

    .noBetsAndCreateContainer,
    .noBetsAndCreateContainerFullPage {
        display: flex;
        flex-direction: column;
        min-height: 10rem;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .noBetsAndCreateContainerFullPage {
        height: 100%;
    }

    .noBetsContainer {
        margin-bottom: 1rem;
    }

    .noBetsContainerGrow {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .betModFooter,
    .betRegularUserFooter {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        font-size: 0.9rem;
        padding-bottom: 0.3rem;
    }

    .betModFooter {
        padding: 0.5rem;
    }

    .betRegularUserFooter {
        padding: 0.5rem 0;
    }

    .betModFooterItem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .betModFooterLeftItems {
        display: flex;
        flex-direction: row;
        gap: 0.3rem;
    }

    .betModFooterRightItems {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 0.3rem;
    }

    .betModFooterItemImage {
        display: flex;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
    }

    .betModFooterItemText {
        margin-left: -0.3rem;
    }

    /** mark trending logic **/
    .markTrendingContainer {
        width: 80vw;
    }

    /** mark offline logic **/
    .markOfflineContainer {
        height: 12rem !important;
    }

    /** create stream logic **/
    .reportStreamContainer {
        height: 16rem !important;
    }

    /** update commentator logic**/
    .updateCommentatorPopupContainer {
        width: 80vw;
        height: 15rem !important;
    }

    /** swap stream logic **/
    .swapStreamContainer {
        height: 18rem !important;
    }

    /** resolve logic **/
    .resolveBetContainer {
        width: 80vw;
    }

    .commentatorTutorialPopupContainer {
        width: 80vw !important;
        height: 18rem;
    }

    .selectedResolveOptionButton {
        border: 2px #257AFD solid;
        color: #257AFD;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .unselectedResolveOptionButton {
        border: 1px black solid;
    }

    .unselectedResolveOptionButton,
    .selectedResolveOptionButton {
        width: 20rem;
        height: 2.5rem;
        justify-content: center;
        word-break: break-word;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        flex-grow: 0;
        font-size: 1.2rem;
        background-color: white;
        padding-left: var(--button-padding-x);
        padding-right: var(--button-padding-x);
        padding-top: var(--button-padding-y);
        padding-bottom: var(--button-padding-y);
        margin-bottom: 0.65em;
        border-radius: var(--button-border-radius);
        cursor: pointer;
    }

    /** share popup **/
    .sharePopupContainer {
        width: 80vw;
        height: 16rem;
        flex-direction: column;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .tutorialTextImportant {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .tutorialCallToActionFooter {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-self: center;
        text-decoration: underline;
        cursor: pointer;
    }

    .createBetContainer {
        padding: 1.5rem;
        width: 80vw;
        height: 36rem !important;
        max-width: 90%;
        font-size: 1.1rem;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }

    .popupHeader {
        display: flex;
        justify-content: flex-end;
    }

    .confirmMarkTrendingText {
        align-self: center;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .confirmUpdateCommentatorInputContainer {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        position: relative;
    }

    .inCreateUpdateCommentatorInput {
        width: 100% !important;
        height: 1.8rem;
        margin-top: 0.7rem;
        font-size: 1.1rem;
        padding: 0.4rem 9rem 0.4rem 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .useMyAddressAsCommentatorText {
        padding-top: 0.5rem;
        font-size: 1rem;
        text-decoration: underline;
        white-space: nowrap;
        position: absolute;
        right: 10px;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .popupCloseIcon {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .betDisplayNameInput {
        width: 80%;
        height: 1.8rem;
        margin-top: 0.5rem;
    }

    .betDisplayNameInput,
    .optionNameInput {
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .betOptionsContainer {
        margin-top: 1rem;
        margin-bottom: 2rem;
        flex-grow: 1;
    }

    .betOptionItemContainer {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
    }

    .optionNameInput {
        width: 80vw;
        height: 1.8rem;
    }

    .addMoreOptions {
        margin-top: 0.5rem;
        font-size: 1rem;
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }

    .removeOptionIcon {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.35rem;
    }

    .createBetFooter {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-self: center;
    }

    .lastCreateBetFeeHint {
        margin-top: 0.2rem;
        font-size: 0.9rem;
    }

    .createBetFeeHint {
        font-size: 0.9rem;
        margin-top: 0.8rem;
    }

    .create-bet-btn {
        align-self: center;
        height: 2.5rem;
        font-size: 1.4rem;
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .betNowText {
        color: blue;
        margin-left: 0.3rem;
        text-decoration: underline;
        cursor: pointer;
    }

    .streamerRoomUserToolBox {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    .streamerRoomChatBox {
        position: relative;
        width: 100%;
        padding: 0.6rem;
        padding-right: 0.8rem !important;
        display: flex;
        align-items: center;
    }

    .streamerRoomChatInput {
        width: 100%;
        min-height: 1.5rem;
        border-radius: 0.5vw;
        box-sizing: border-box;
        padding: 0.5rem;
        padding-right: 2.75rem;
        font-size: 0.9rem;
    }

    .giftSendButton {
        cursor: pointer;
        height: 2rem;
        align-self: center;
    }

    .superChatSendButton {
        cursor: pointer;
        height: 2rem;
        align-self: center;
    }
}

@media (min-width:600px) {
    .chatMessageDisplayText {
        max-width: 265px;
    }

    .commentateStreamPlayer {
        z-index: 500;
        display: flex;
        width: 15vw;
        height: calc(15vw * 720 / 1280);
        position: absolute;
        top: 0;
        right: 0;
    }

    .featuredStreamPlayer {
        border-bottom: 1px solid var(--color-border) !important;
    }

    .countdownTextContainer {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .twitter-tweet {
        margin: 0px auto !important;
    }

    .streamChat {
        width: var(--chat-width);
        border-width: 0 1px 1px 1px;
    }

    /** bets **/
    .betsContainer {
        width: var(--chat-width);
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .trendingBetsContainer {
        width: var(--chat-width);
        max-height: 20rem;
    }

    .trendingBetContainer {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc((100vw - var(--chat-width)) * 720 / 1280 - 2.2rem - 1px);
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .allBetsBetsContainer {
        justify-content: flex-start;
        overflow-y: scroll;
        height: calc((100vw - var(--chat-width)) * 720 / 1280 - 3rem - 2.2rem - 1px);
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        width: 100%;
    }

    .betItemContainer {
        width: var(--chat-width);
        border-bottom: 1px solid var(--color-border);
        padding: 0.8rem 1rem;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    /** bet header **/
    .streamTabContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 2.2rem;
    }

    .streamTabContainerItem,
    .selectedStreamTabContainer {
        flex: 1;
        text-align: center;
        padding: 0.5rem;
        border-top: none !important;
    }

    .rightMostStreamTabContainerItem {
        border-right: none;
    }

    .selectedStreamTabContainer {
        border-top: solid 1px black;
    }

    /** selected bet*/
    .selectedBetItemHeader {
        display: flex;
        flex-direction: row;
        word-break: break-word;
        font-weight: 500;
    }

    .placeBetCloseIcon {
        margin-left: auto;
        width: 1rem;
        height: 1rem;
    }

    .selectedBetInputContainer {
        position: relative;
        margin-top: 0.5rem;
    }

    .selectedBetAmountInput {
        width: calc(100% - 1rem);
        height: 1.8rem;
        margin-top: 0.5rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .buttonContainer {
        position: absolute;
        top: calc((1.8rem + 0.4rem * 2 - 1.7rem) / 2 + 0.5rem);
        right: 0.55rem;
        display: flex;
        gap: 0.5rem;
    }

    .selectedBetPlus {
        background-color: var(--colors-gray-3);
        color: var(--colors-gray);
        font-size: 1.1rem;
        padding: 0.3rem;
        height: 1.7rem;
        border-radius: 0.4rem;
    }

    .selectedBetPlus:hover {
        background-color: var(--colors-gray-2);
    }

    .afterBetWinTicketsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.6rem;
    }

    .betBoxToWinCoinImage {
        width: 1.05rem;
        height: 1.05rem;
        margin-left: 0.3rem;
        margin-right: 0.2rem;
    }

    .placeBetBtn {
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem 0;
        align-items: center;
        justify-content: center;
    }

    .placeBetHintContainer {
        display: flex;
        flex-direction: column;
    }

    /** unselected bet*/
    .betTitleContainer {
        display: flex;
        flex-direction: row;
        padding-bottom: 0.6rem;
        line-height: 1.5rem;
        word-break: break-word;
    }

    .betTitleDecisionContainer {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center
    }

    .betTitleDecisionHintTrigger {
        margin-right: 0.5rem;
    }

    .betItemOptionName {
        word-break: break-word;
        padding-right: 1rem;
    }

    .betItemOptionsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
    }

    .betItemOptionOdds {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .betItemOptionOddsHint {
        color: var(--colors-gray);
        font-size: 0.85rem;
        margin-left: 0.2rem;
        font-weight: 500;
    }

    .userBettedAmount {
        text-align: end;
        font-size: 0.85rem;
        margin-top: 0.35rem;
        color: var(--colors-gray);
    }

    .betBtn {
        margin-left: 0.5rem;
        font-size: 1.15rem;
        padding: 0.25rem 0.8rem;
    }

    .betBtn:hover {
        background-color: hsl(195, 100%, 64%);
    }

    .betItemFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: 0.85rem;
        font-weight: 500;
        margin-top: 0.6rem;
        color: var(--colors-gray);
    }

    .betItemTVL {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .betBoxCoinImage {
        margin-left: 0.4rem;
        margin-right: 0.15rem;
        width: 0.9rem;
        height: 0.9rem;
    }

    .betLivenessContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: 0.1rem;
    }

    .betLivenessIcon {
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 0.25rem;
        border-radius: 50%;
        background-color: green;
    }

    .shareBetContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .shareBetIcon {
        width: 0.9rem;
        height: 0.9rem;
        margin-left: 0.5rem;
        margin-right: 0.25rem;
    }

    .shareBetLink {
        text-decoration: underline;
    }

    .twitterShare {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .twitterShareIcon {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
    }

    .twitterShareLink {
        font-size: 1rem;
    }

    .shareUrlContainer {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        border: 1px solid black;
        border-radius: 0.5rem;
        padding: 0.4rem;
        align-items: center;
    }

    .shareUrlText {
        max-width: 75%;
        overflow: hidden;
    }

    .shareUrlContainerCopyButton {
        margin-left: auto;
    }

    .noBetsAndCreateContainer,
    .noBetsAndCreateContainerFullPage {
        display: flex;
        flex-direction: column;
        min-height: 10rem;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .noBetsAndCreateContainerFullPage {
        height: 100%;
    }

    .noBetsContainer {
        margin-bottom: 1rem;
    }

    .noBetsContainerGrow {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .betModFooter,
    .betRegularUserFooter {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        font-size: 0.9rem;
    }

    .betModFooter {
        padding: 0.5rem;
    }

    .betRegularUserFooter {
        padding: 0.5rem 0;
    }

    .betModFooterItem {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .betModFooterLeftItems {
        display: flex;
        flex-direction: row;
        gap: 0.3rem;
    }

    .betModFooterRightItems {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 0.3rem;
    }

    .betModFooterItemImage {
        display: flex;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
    }

    .betModFooterItemText {
        margin-left: -0.3rem;
    }

    /** mark trending logic **/
    .markTrendingContainer {
        width: 25rem;
    }

    /** mark offline logic **/
    .markOfflineContainer {
        height: 12rem !important;
    }

    /** create stream logic **/
    .reportStreamContainer {
        height: 12rem !important;
    }

    /** update commentator logic**/
    .updateCommentatorPopupContainer {
        width: 25rem;
        height: 15rem !important;
    }

    /** swap stream logic **/
    .swapStreamContainer {
        height: 18rem !important;
    }

    /** resolve logic **/
    .resolveBetContainer {
        width: 25rem;
    }

    .commentatorTutorialPopupContainer {
        width: 30rem !important;
        height: 18rem;
    }

    .selectedResolveOptionButton {
        border: 2px #257AFD solid;
        color: #257AFD;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    }

    .unselectedResolveOptionButton {
        border: 1px black solid;
    }

    .unselectedResolveOptionButton,
    .selectedResolveOptionButton {
        width: 20rem;
        height: 2.5rem;
        justify-content: center;
        word-break: break-word;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        flex-grow: 0;
        font-size: 1.2rem;
        background-color: white;
        padding-left: var(--button-padding-x);
        padding-right: var(--button-padding-x);
        padding-top: var(--button-padding-y);
        padding-bottom: var(--button-padding-y);
        margin-bottom: 0.65em;
        border-radius: var(--button-border-radius);
        cursor: pointer;
    }

    /** share popup **/
    .sharePopupContainer {
        width: 30rem;
        height: 15rem;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .tutorialTextImportant {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .tutorialCallToActionFooter {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-self: center;
        text-decoration: underline;
        cursor: pointer;
    }

    .createBetContainer {
        padding: 1.8rem;
        width: 30rem;
        height: 33rem !important;
        max-width: 90%;
        box-sizing: border-box;
        font-size: 1.1rem;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .popupHeader {
        display: flex;
        justify-content: flex-end;
    }

    .confirmMarkTrendingText {
        align-self: center;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
    }

    .confirmUpdateCommentatorInputContainer {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        position: relative;
    }

    .inCreateUpdateCommentatorInput {
        width: 100% !important;
        height: 1.8rem;
        margin-top: 0.7rem;
        font-size: 1.1rem;
        padding: 0.4rem 9rem 0.4rem 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .useMyAddressAsCommentatorText {
        padding-top: 0.5rem;
        font-size: 1rem;
        text-decoration: underline;
        white-space: nowrap;
        position: absolute;
        right: 10px;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .popupCloseIcon {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .betDisplayNameInput {
        width: calc(100% - 1.5rem);
        height: 1.8rem;
        margin-top: 0.5rem;
    }

    .betDisplayNameInput,
    .optionNameInput {
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .betOptionsContainer {
        margin-top: 1rem;
        margin-bottom: 2rem;
        flex-grow: 1;
    }

    .betOptionItemContainer {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
    }

    .optionNameInput {
        width: 100%;
        height: 1.8rem;
    }

    .addMoreOptions {
        margin-top: 0.5rem;
        font-size: 1rem;
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }

    .removeOptionIcon {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.35rem;
    }

    .createBetFooter {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-self: center;
    }

    .lastCreateBetFeeHint {
        margin-top: 0.2rem;
        font-size: 0.9rem;
    }

    .createBetFeeHint {
        font-size: 0.9rem;
        margin-top: 0.8rem;
    }

    .create-bet-btn {
        align-self: center;
        height: 2.5rem;
        font-size: 1.4rem;
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .betNowText {
        color: blue;
        margin-left: 0.3rem;
        text-decoration: underline;
        cursor: pointer;
    }

    .streamerRoomUserToolBox {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    .streamerRoomChatBox {
        position: relative;
        width: 100%;
        padding: 0.6rem;
        padding-right: 0.8rem !important;
        display: flex;
        align-items: center;
    }

    .streamerRoomChatInput {
        width: 100%;
        min-height: 3rem;
        border-radius: 0.5vw;
        box-sizing: border-box;
        padding: 0.5rem;
        padding-right: 2.75rem;
        font-size: 0.9rem;
    }

    .giftSendButton {
        cursor: pointer;
        height: 2rem;
        align-self: center;
    }

    .superChatSendButton {
        cursor: pointer;
        height: 2rem;
        align-self: center;
    }
}

.chat-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-input-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: env(safe-area-inset-bottom);
}

.chat-input {
    width: 99%;
    box-sizing: border-box;
    height: 32px;
    padding: 5px 15px;
}

@supports (-webkit-touch-callout: none) {

    /* iOS specific styles */
    .chat-input-container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--color-background-base);
        padding: 8px;
        z-index: 1000;
    }

    .chat-input-wrapper {
        padding-bottom: max(env(safe-area-inset-bottom), 20px);
    }
}