.tutorialLinks {
    display: flex;
    flex-wrap: wrap;
    height: 45px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.tutorialLinks a {
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    color: var(--color-light);
    margin: 2px;
}

.tutorialLinks_bet {
    height: 40px;
    display: flex;
    align-items: center;
}

@media (max-height:500px),
(max-width:500px) {
    .tutorialLinks {
        flex-direction: row;
    }

    .tutorialLinks a {
        font-size: 0.8rem;
    }
}


.input {
    width: 90%;
    height: 1.8rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    padding: 0.4rem;
    border: 0.1rem solid #000000;
    border-radius: 0.3rem;
}

.createStreamHeader {
    display: flex;
    justify-content: space-between;
}

.navbarLinkContainer {
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: none;
    color: var(--color-light);
}

.navbarLogo {
    cursor: pointer;
}

@media (max-height: 500px) {
    .navbarLogo {
        width: 2.5rem;
        height: 2.5rem;
    }

    .navbarLogo:active {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
    }
}

@media (min-height: 500px) {
    .navbarLogo {
        width: 3rem;
        height: 3rem;
    }

    .navbarLogo:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
    }
}

.navbarLinkContainer:hover {
    font-weight: 600;
}

.highlightOnHover:hover {
    text-decoration: underline;
}

.showPastStreamLink {
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--color-light-2);
    text-decoration: underline;
    cursor: pointer;
}

.avatarSmall {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}


.topnav {
    display: flex;
    position: fixed;
    font-size: 1.35rem;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    z-index: 1000;
    border-bottom: var(--color-border) 1px solid;
    background-color: var(--color-background-base) !important;
}

@media (max-height:500px) {
    .topnav {
        height: 2.5rem;
    }
}

@media (min-height:500px) {
    .topnav {
        height: 3.5rem;
    }
}

input,
textarea {
    background-color: var(--color-dark-2);
    border: 1px solid var(--color-border);
    color: var(--color-light);
    border-radius: 5px;
}

::placeholder {
    color: var(--color-light-2)
}

.btn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    flex-grow: 0;
    background-color: var(--color-btn);
    color: #ffffff;
    border: black solid 1px;
    border-radius: var(--button-border-radius);
    cursor: pointer;
    justify-content: center;
}

.btn,
.btn-dark {
    font-size: 1.2rem;
    padding: 0.35rem 0.65rem;
}

@media (max-height:500px),
(max-width:500px) {

    .btn,
    .btn-dark {
        font-size: 0.72rem;
        padding: 0.35rem 0.4rem;
    }
}


.btn:hover {
    background-color: #0284c7;
}

.btn-dark:hover {
    background-color: #374151;
}

.btn-dark {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    flex-grow: 0;
    background-color: var(--color-background-base);
    color: #ffffff;
    border: black solid 1px;
    border-radius: var(--button-border-radius);
    cursor: pointer;
}

.futureStreamContentContainer {
    display: flex;
    flex-direction: row;
    background-color: var(--color-dark-2);
    border: solid 1px var(--color-border);
    cursor: pointer;
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 0.2rem;
}

.tutorialContainer {
    z-index: 9999;
    background-color: var(--color-dark-3);
    border: 1px solid var(--color-border);
}

.overlay {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: none;
    backdrop-filter: blur(10px);
    /* Adjust the alpha value to control the transparency */
    z-index: 1000;
    /* Ensure it appears above other content but below the coinSelectionBox */
}

.createStreamHeaderTab {
    display: flex;
    flex-direction: row;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.createStreamHeaderTabItem,
.createStreamHeaderTabSelectedItem {
    flex: 1 1;
    text-align: center;
    padding: 0.5rem 0;
    cursor: pointer;
}

.createStreamHeaderTabItem {
    border-bottom: solid 1px white;
}

.createStreamHeaderTabSelectedItem {
    border: solid 1px white;
    border-bottom: none !important;
}

.previewStreamContainer {
    cursor: pointer;
    position: relative;
}

.createStreamContainer,
.createFeaturedStreamContainer,
.reportStreamContainer {
    border: 1px solid var(--color-border);
    background-color: var(--color-dark-3);
}

.inCreateStreamStartTimeItem {
    margin-right: 0.3rem;
}

.secretKeyPopupContainer {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.refererFooterContainer {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    font-size: 1rem;
}

.referralCoinImage {
    width: 1rem;
    height: 1rem;
    margin-left: 0.35rem;
    margin-right: 0.2rem;
}

.pfpContainer {
    justify-content: center;
    align-items: center;
    position: relative;
    display: inline-block;
    height: 6rem !important;
}

.walletPageEthIcon,
.walletPagePfpImage {
    margin-right: 0.8rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
}




/* default *?


    /** loading view **/
.landing-container {
    height: calc(100vh - 3.5rem);
    /* Full height of the viewport, minus the height of topnav */
    display: flex;
    flex-direction: row;
}

.discoverLoadingViewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

/** navbar **/

.topnavLeftArea {
    display: flex;
    align-items: center;
    margin-left: var(--topnav-margin-x);
    margin-right: auto;
}

.homeIconContainer {
    margin-right: 1rem;
}

.topnavRightArea {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: var(--topnav-margin-x);
}

.topnavCoinStarRightArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    font-size: 1rem;
}

.login-btn {
    padding: 0.5rem 1rem;
}

.navbarPotCointainer,
.navbarCoinCointainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbarCoinImage {
    width: 2.2rem;
}

.navbarCoinAmount {
    margin-left: 0.3rem;
    margin-right: 0.6rem;
    font-size: 1.35rem;
}

.spinner-rotate {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/** landing page **/
.landingViewContainer {
    padding-top: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100vw;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
}

.landingViewTopPanel {
    display: flex;
    width: 100%;
    margin-top: 3.5rem;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
}

.addStreamIcon {
    width: 2rem;
    height: 2rem;
}

.addStreamText {
    margin-left: 0.4rem;
    font-size: 1.1rem;
}

.addStreamContainer {
    z-index: 11;
    /* 1 higher than overlay */
    position: absolute;
    float: right;
    right: 1rem;
    top: calc((4rem - 2rem) / 2);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/** king of the hill **/
#red-hot-stream-explainer {
    z-index: 100000;
}

.topStreamContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
}

.topStreamContainer div {
    margin-bottom: 0 !important;
}

.topStreamIcon {
    height: 4rem;
}

.topStreamItem {
    margin-top: 1rem;
}

/** wallet page **/
.walletContainer {
    margin-top: 4.5rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
}

.walletItemContainer {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Vertical center alignment */
}

.walletDepositContainer {
    margin-left: calc(2rem + 0.8rem - 0.2rem);
}

.walletDepositContainer div {
    margin-left: 0.2rem;
}

.onramperFrame {
    margin-bottom: 1rem;
}

.buyUSDCBtn {
    font-size: 1rem;
    margin: 0 0.5rem;
}

.buyCoinInputBar {
    position: relative;
    display: inline-block;
}

.buyCoinInput {
    width: 100%;
    /* Adjust based on your design needs */
    padding-right: 50px;
    /* Add padding to the right to ensure text does not overlap */
}

.maxBuyLink {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    font-size: 0.9rem;
    transform: translateY(-50%);
    color: gray;
    text-decoration: underline;
    cursor: pointer;
}

.walletPageAddressCopy {
    margin-left: 0.3rem;
    color: var(--colors-gray);
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.leaderboardItem {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}

.leaderboardItem a {
    color: white;
}

.leaderboardCoinContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
}

.leaderboardCoinImage {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
}

/** referral **/
.refererIntroText {
    margin-bottom: 1rem;
}

.refererIntroTextLast {
    margin-bottom: -0.5rem;
}

.secretKeyContainer {
    margin-top: auto 0;
}

.walletActionContainer {
    display: flex;
    flex-direction: horizontal;
    gap: 1rem;
}

.secretKeyContainerFooter {
    font-size: 1rem;
}

.walletPageEthBalanceInput {
    margin-left: 0.3vw;
    font-size: 1rem !important;
    width: 5vw;
    height: 2vw;
}

.walletPageEthAddressInput {
    margin-left: 0.3vw;
    margin-right: 0.5vw;
    font-size: 1rem !important;
    width: 12vw;
    height: 2vw;
}

.withdrawPopupInput {
    width: 60%;
    height: 1.8rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    padding: 0.4rem;
    border: 0.1rem solid black;
    border-radius: 0.3rem;
}

.walletPagePointImage {
    border-radius: 0 !important;
}

.buyCoinInput {
    width: 6rem;
    height: 1.2rem;
    font-size: 1.1rem;
    padding: 0.4rem;
    border: 0.1rem solid black;
    border-radius: 0.3rem;
}

.buy-coin-btn,
.sell-coin-btn {
    margin-top: 1rem;
    font-size: 1rem;
}

.sell-coin-btn {
    color: black;
    background-color: pink;
}

.logoutContainer {
    margin-left: 0.5rem;
}

.editDisplayNameButton {
    cursor: pointer;
    width: 1.2rem;
}

.logoutText {
    color: var(--colors-gray);
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.streamsSection {
    margin-bottom: 15px;
}

/** stream view **/
.streamsSectionHeader {
    margin-left: 1rem;
    font-size: 1.5rem;
}

.addStreamContainerText {
    margin-top: 1px;
}

.pastStreamHeader {
    margin-top: 1rem;
}

.futureStreamsContainer {
    padding: 10px;
}

.futureStreamDateHeader {
    margin-top: 0.5rem;
    font-size: 1.2rem;
}

.futureStreamContentContainer {
    max-width: 40rem;
}

.futureStreamContent {
    display: flex;
    flex-direction: column;
}

.futureStreamContentTitle,
.futureStreamContentCaret {
    font-weight: 600;
}

.streamSubscribeButton {
    font-size: 1.2rem !important;
    padding: 0.5rem !important;
    border: 1px solid rgb(0, 186, 250);
}

.futureStreamContentTime {
    font-size: 1rem;
    margin-top: 0.4rem;
    margin-right: 0.4rem;
    color: gray;
}

.futureStreamContentCaret {
    margin-left: auto;
    align-self: center;
}

.featuredPreviewStreamPlayer {
    display: flex;
    flex-direction: column;
    border: solid black 2px;
    margin-bottom: 0.2rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.countdownTextContainer {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;
}

.streamCountdownText {
    font-size: 1.3rem;
}

.previewStreamOverlayTitle {
    position: absolute;
    top: 2rem;
    left: 1rem;
    background-color: black;
    color: white;
    z-index: 1000;
    font-size: 1.3rem;
    max-width: calc(100% - 3rem);
}

.streamPreviewLivenessOverlay {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: black;
    color: white;
    z-index: 11;
    padding: 0.2rem;
    font-size: 1.1rem;
}

.previewStreamOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 10;
    /* Ensure the overlay is on top of the iframe */
}

.topStreamPreviewFooter {
    margin-bottom: 0rem !important;
}

.topStreamPreviewFooter,
.streamPreviewFooter {
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    align-items: center;
    padding-top: 0.2rem;
}

.streamPreviewCoinIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.3rem;
    margin-right: 0.15rem;
}

.streamUserNameTitle {
    margin-bottom: 0.5rem;
}

.updateDisplayNameButton {
    margin-top: 2rem;
}

.pfpContainer {
    justify-content: center;
    align-items: center;
    position: relative;
    display: inline-block;
}

.pfpImage {
    cursor: pointer;
    position: relative;
    left: calc(50% - 2rem);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: block;
    align-self: center;
    justify-content: center;
}

.uploadPfpOverlay {
    position: relative;
    left: calc(50% - 2rem);
    width: 4rem;
    /* Adjust the size of the overlay circle */
    height: 4rem;
    opacity: 0.9;
    /* Adjust the value to set the desired level of transparency */
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust color and transparency */
    transform: translate(0%, -4rem);
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploadPfp {
    cursor: pointer;
    width: 1rem;
    /* Adjust size to fit well within the circle */
    position: relative;
    pointer-events: auto;
    /* Ensure the image captures clicks */
}

.pfpUploadIcon {
    display: none;
}

/** tutorial **/
.tutorialContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    padding: 1.8rem;
    width: 25rem;
    height: 18rem;
    max-width: 90%;
    font-size: 1.2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.tutorialHowToPlay {
    justify-content: center;
    text-align: center;
    margin-bottom: 0.8rem;
}

.tutorialContentHeader {
    font-weight: 500;
    font-size: 1.1rem;
}

.tutorialContentBody {
    font-size: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
}

.tutorialContentBody>div {
    line-height: 1.3rem;
    margin-top: 0.4rem;
}

/** create stream **/
.createStreamContainer,
.createFeaturedStreamContainer,
.reportStreamContainer {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    padding: 1.8rem;
    width: 25rem;
    height: 24rem;
    max-width: 90%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
}

.createFeaturedStreamContainer {
    height: 28rem;
}

.createStreamCloseIcon {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}

.createStreamFooter {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    align-self: center;
}

.createStreamBtn {
    align-self: center;
    height: 2.5rem;
    font-size: 1.4rem;
    padding: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.streamFeesHint {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
}

.streamPlatformHint {
    margin-top: 0.3rem;
    font-size: 0.9rem;
}

.inCreateStreamerUserNameInput {
    width: 90%;
    height: 1.8rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    padding: 0.4rem;
    border: 0.1rem solid black;
    border-radius: 0.3rem;
}

.streamerChannelContainer {
    margin-top: 1.5rem;
}

.streamerChannelOptionContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: max(16px, min(1.2vw, 32px));
}

.streamChannelOption,
.selectedStreamChannelOption {
    margin-top: 0.7rem;
    margin-right: 1.5rem;
    padding: 0.7vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.7rem;
}

.streamChannelOption {
    border: solid 1px #000000;
}

.selectedStreamChannelOption {
    font-weight: 600;
    color: rgb(0, 186, 250);
    border: solid 2px rgb(0, 186, 250);
}

.countdownStreamHint {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer;
}

.inCreateStreamStartTimeContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.inCreateStreamStartTimeContainer input {
    width: 2rem;
    height: 1.8rem;
    margin-top: 0.7rem;
    font-size: 1.1rem;
    padding: 0.4rem;
    border: 0.1rem solid black;
    border-radius: 0.3rem;
    margin-right: 0.2rem;
}

.inCreateStreamerYearInput {
    width: 3rem !important;
}

/* small scerrn */
@media (max-height:500px),
(max-width:600px) {

    /** loading view **/
    .landing-container {
        height: calc(100vh - 3.5rem);
        /* Full height of the viewport, minus the height of topnav */
        display: flex;
        flex-direction: row;
    }

    .discoverLoadingViewContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    /** navbar **/

    .topnavLeftArea {
        display: flex;
        align-items: center;
        margin-left: var(--topnav-margin-x-mobile);
        margin-right: auto;
    }

    .homeIconContainer {
        margin-right: 0.7rem;
        padding-top: 5px;
    }

    .topnavRightArea {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: var(--topnav-margin-x-mobile);
        font-size: 1rem;
    }

    .topnavCoinStarRightArea {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-left: auto;
        margin-right: var(--topnav-margin-x-mobile);
        font-size: 1rem;
        gap: 0.25rem;
    }

    .navbarPotCointainer {
        display: none;
    }

    .navbarCoinCointainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .navbarCoinImage {
        width: 1.2rem;
    }

    .navbarCoinAmount {
        margin-left: 0.2rem;
        margin-right: 0.3rem;
        font-size: 1rem;
    }

    .buy-coins-btn {
        padding: 0.3rem 0.5rem;
        margin-left: auto;
    }

    /** landing page **/
    .landingViewContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 100vw;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .landingViewTopPanel {
        display: flex;
        width: 100%;
        margin-top: 3.5rem;
        flex-direction: row;
        align-items: flex-end;
        position: relative;
    }

    .addStreamIcon {
        width: 1.4rem;
        height: 1.4rem;
    }

    .addStreamText {
        margin-left: 0.1rem;
        font-size: 1rem;
    }

    .addStreamContainer {
        z-index: 11;
        /* 1 higher than overlay */
        position: absolute;
        float: right;
        right: 1rem;
        top: 0;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    /** king of the hill **/
    #red-hot-stream-explainer {
        z-index: 100000;
    }

    .topStreamContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3.5rem;
        margin-bottom: -0.5rem;
    }

    .topStreamContainer div {
        margin-bottom: 0 !important;
    }

    .topStreamIcon {
        height: 3rem;
    }

    .topStreamItem {
        margin-top: 1rem;
    }

    /** wallet page **/
    .walletContainer {
        margin-top: 4.5rem;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
    }

    .walletItemContainer {
        margin-bottom: 0.8rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        /* Vertical center alignment */
    }

    .walletDepositContainer {
        margin-left: calc(2rem + 0.8rem - 0.2rem);
    }

    .walletDepositContainer div {
        margin-left: 0.2rem;
    }

    .onramperFrame {
        margin-left: 0.2rem !important;
        margin-bottom: 1rem;
    }

    .buyUSDCBtn {
        font-size: 1rem;
        margin: 0 0.5rem;
    }

    .buyCoinInputBar {
        position: relative;
        display: inline-block;
    }

    .buyCoinInput {
        width: 100%;
        /* Adjust based on your design needs */
        padding-right: 50px;
        /* Add padding to the right to ensure text does not overlap */
    }

    .maxBuyLink {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        font-size: 0.9rem;
        transform: translateY(-50%);
        color: gray;
        text-decoration: underline;
        cursor: pointer;
    }

    .walletPageAddressCopy {
        margin-left: 0.3rem;
        color: var(--colors-gray);
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }

    .leaderboardItem {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
    }

    .leaderboardItem a {
        color: white;
    }

    .leaderboardCoinContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
    }

    .leaderboardCoinImage {
        width: 1rem;
        height: 1rem;
        margin-right: 0.2rem;
    }

    /** referral **/
    .refererIntroText {
        margin-bottom: 1rem;
    }

    .refererIntroTextLast {
        margin-bottom: -0.5rem;
    }

    .secretKeyContainer {
        margin-top: auto 0;
    }

    .walletActionContainer {
        display: flex;
        flex-direction: horizontal;
        gap: 1rem;
    }

    .secretKeyContainerFooter {
        font-size: 1rem;
    }

    .walletPageEthBalanceInput {
        margin-left: 0.3vw;
        font-size: 1rem !important;
        width: 5vw;
        height: 2vw;
    }

    .walletPageEthAddressInput {
        margin-left: 0.3vw;
        margin-right: 0.5vw;
        font-size: 1rem !important;
        width: 12vw;
        height: 2vw;
    }

    .withdrawPopupInput {
        width: 60%;
        height: 1.8rem;
        margin-top: 0.7rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .walletPagePointImage {
        border-radius: 0 !important;
    }

    .buyCoinInput {
        width: 5rem;
        height: 1.2rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .buy-coin-btn,
    .sell-coin-btn {
        margin-top: 1rem;
        font-size: 1rem;
    }

    .sell-coin-btn {
        color: black;
        background-color: pink;
    }

    .editDisplayNameButton {
        cursor: pointer;
        width: 1.2rem;
    }

    .logoutContainer {
        margin-left: 0.5rem;
    }

    .logoutText {
        color: var(--colors-gray);
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }

    /** stream view **/
    .sreamsSectionHeader {
        display: flex;
        flex-direction: row;
        font-size: 1.3rem;
    }

    .pastStreamHeader {
        margin-top: 1rem;
    }

    .futureStreamsContainer {
        padding: 0;
    }

    .futureStreamDateHeader {
        margin-top: 0.5rem;
        font-size: 1.2rem;
    }

    .futureStreamContent {
        display: flex;
        flex-direction: column;
    }

    .futureStreamContentTitle,
    .futureStreamContentCaret {
        font-weight: 600;
    }

    .streamSubscribeButton {
        font-size: 1.2rem !important;
        padding: 0.5rem !important;
        border: 2px solid rgb(0, 186, 250);
    }

    .futureStreamContentTime {
        font-size: 1rem;
        margin-top: 0.4rem;
        margin-right: 0.4rem;
        color: gray;
    }

    .futureStreamContentCaret {
        padding-left: 1rem;
        margin-left: auto;
        align-self: center;
    }


    .featuredPreviewStreamPlayer {
        display: flex;
        flex-direction: column;
        border: solid black 2px;
        margin-bottom: 0.2rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .countdownTextContainer {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        text-align: center;
    }

    .streamCountdownText {
        font-size: 1.3rem;
    }

    .previewStreamOverlayTitle {
        position: absolute;
        top: 2rem;
        left: 1rem;
        background-color: black;
        color: white;
        z-index: 1000;
        font-size: 1.2rem;
        max-width: calc(100% - 3rem);
    }

    .streamPreviewLivenessOverlay {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: black;
        color: white;
        z-index: 11;
        padding: 0.2rem;
        font-size: 1rem;
    }

    .previewStreamOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 10;
        /* Ensure the overlay is on top of the iframe */
    }

    .topStreamPreviewFooter {
        margin-bottom: 0rem !important;
    }

    .topStreamPreviewFooter,
    .streamPreviewFooter {
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        align-items: center;
    }

    .streamPreviewCoinIcon {
        width: 1rem;
        height: 1rem;
        margin-left: 0.3rem;
        margin-right: 0.15rem;
    }

    .twitter-tweet {
        margin-bottom: 20rem !important;
    }

    .streamUserNameTitle {
        margin-bottom: 0.5rem;
    }

    .updateDisplayNameButton {
        margin-top: 2rem;
    }

    .pfpImage {
        cursor: pointer;
        position: relative;
        left: calc(50% - 2rem);
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        display: block;
        align-self: center;
        justify-content: center;
    }

    .uploadPfpOverlay {
        position: relative;
        left: calc(50% - 2rem);
        width: 4rem;
        /* Adjust the size of the overlay circle */
        height: 4rem;
        opacity: 0.9;
        /* Adjust the value to set the desired level of transparency */
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        /* Adjust color and transparency */
        transform: translate(0%, -4rem);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .uploadPfp {
        cursor: pointer;
        width: 1rem;
        /* Adjust size to fit well within the circle */
        position: relative;
        pointer-events: auto;
        /* Ensure the image captures clicks */
    }

    .pfpUploadIcon {
        display: none;
    }

    /** tutorial **/
    .tutorialContainer {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
        padding: 1.8rem;
        width: 25rem;
        height: 28rem;
        max-width: 90%;
        box-sizing: border-box;
        font-size: 1.2rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .tutorialHowToPlay {
        justify-content: center;
        text-align: center;
        margin-bottom: 0.8rem;
    }

    .tutorialContentHeader {
        font-weight: 500;
        font-size: 1.1rem;
    }

    .tutorialContentBody {
        font-size: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .tutorialContentBody>div {
        line-height: 1.3rem;
        margin-top: 0.4rem;
    }

    /** create stream **/
    .createStreamContainer,
    .createFeaturedStreamContainer,
    .reportStreamContainer {
        z-index: 1001;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
        padding: 1.8rem;
        width: 25rem;
        max-width: 90%;
        box-sizing: border-box;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .createStreamContainer {
        height: 25rem;
    }

    .createFeaturedStreamContainer {
        width: 28rem;
        height: 30rem;
    }

    .createStreamCloseIcon {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
    }

    .createStreamFooter {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        align-self: center;
    }

    .createStreamBtn {
        align-self: center;
        height: 2.5rem;
        font-size: 1.25rem;
        padding: 1.2rem;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .streamFeesHint {
        margin-bottom: 0.2rem;
        font-size: 0.9rem;
    }

    .streamPlatformHint {
        margin-top: 0.3rem;
        font-size: 0.9rem;
    }

    .inCreateStreamerUserNameInput {
        width: 90%;
        height: 1.8rem;
        margin-top: 0.7rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
    }

    .streamerChannelContainer {
        margin-top: 1.5rem;
    }

    .streamerChannelOptionContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: max(16px, min(1.2vw, 32px));
    }

    .streamChannelOption,
    .selectedStreamChannelOption {
        margin-top: 0.7rem;
        margin-right: 1.5rem;
        padding: 0.7vw;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 0.7rem;
    }

    .streamChannelOption {
        border: solid 1px #000000;
    }

    .selectedStreamChannelOption {
        font-weight: 600;
        color: rgb(0, 186, 250);
        border: solid 2px rgb(0, 186, 250);
    }

    .countdownStreamHint {
        margin-top: 0.7rem;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        text-decoration: underline;
        cursor: pointer;
    }

    .inCreateStreamStartTimeContainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
    }

    .inCreateStreamStartTimeContainer input {
        width: 2rem;
        height: 1.8rem;
        margin-top: 0.7rem;
        font-size: 1.1rem;
        padding: 0.4rem;
        border: 0.1rem solid black;
        border-radius: 0.3rem;
        margin-right: 0.2rem;
    }

    .inCreateStreamerYearInput {
        width: 3rem !important;
    }
}

.selectTime {
    margin-top: 1rem;
}