.askPwaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: 100%;
}

.askPwaLogo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.askPwaLogo img {
    width: 50px;
    height: auto;
}

.askPwaBody {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 10px;
    padding-bottom: 35%;
}

.askPwaLine {
    height: 1px;
    width: 100%;
    background-color: #eee;
}

.askPwaImg {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%
}

.askPwaImgContainer {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: #ccc 1px solid
}

.askPwaImgContainer img {
    width: 100%;
}

.askPwaText {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.askPwaTextHead {
    font-size: 2.25rem;
    font-weight: 600;
}

.askPwaTextBody {
    color: hsl(215.4 16.3% 46.9%);
    text-align: center;
    font-size: 17px;
}

.askPwaImgDes {
    color: hsl(215.4 16.3% 46.9%);
    text-align: center;
    font-size: 13px;
}