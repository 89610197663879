:root {
  --color-white: #ffffff;
  --colors-gray: oklab(0.686636 -0.00407365 -0.0149199);
  --colors-gray-2: #F2F2F2;
  --colors-gray-3: #E0E0E0;
  --color-dark: #313338;
  --color-dark-2: #2B2D31;
  --color-dark-3: #1E1F22;
  --color-btn: rgb(0, 151, 201);
  --color-btn-hover: rgb(0, 125, 167);
  --color-border: #5c5c5c;
  --color-light: #eeeeee;
  --color-light-2: #b3b3b3;
  --color-background-base: var(--color-dark);
  --shadow-elevation-1: 0 1px 2px var(--color-opac-b-3), 0 0px 2px var(--color-opac-b-2);
  --border-radius-medium: 0.4rem;
  --color-opac-b-2: rgba(0, 0, 0, 0.8);
  --color-opac-b-3: rgba(0, 0, 0, 0.13);
  --button-padding-x: 0.65rem;
  --button-padding-y: 0.35rem;
  --button-border-radius: 0.6rem;
  --topnav-margin-x: 2rem;
  --topnav-margin-x-mobile: 0.8rem;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-dark);
  color: var(--color-light);
  overflow-x: hidden;
}

svg {
  fill: var(--color-border)
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
  border-color: var(--color-btn);
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}