.walletOption {
    display: flex;
    border-radius: 20px;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    padding: 10px;
}

.walletOption:hover {
    background-color: var(--color-dark-2);
}

.walletSelectionBtn:hover {
    background-color: var(--color-dark-2);
}