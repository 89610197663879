.superChatContainer {
    position: absolute;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
}

.superChat {
    max-width: 50%;
    min-width: 30%;
    height: auto;
    /* background: rgba(255, 255, 255, 0.5); */
    background: linear-gradient(75deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.35));
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 10px 10px 20px 10px;
    color: black;
    display: flex;
    flex-direction: column;
}

.superChatHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.superChatUser {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.superChatUser img {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.superChatText {
    font-size: 1.1rem;
    white-space: pre-wrap;
    word-wrap: break-word;
}

@media (max-width:600px) {
    .superChat {
        max-width: 80%;
    }
}